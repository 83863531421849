import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IonPage,
  IonModal,
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonIcon,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { MessageRepository, MessageContentType, Client } from '@amityco/ts-sdk';
import { AuthContext } from '../authentication/AuthContext';
import RecentChats from './RecentChats';
import ChatContent from './ChatContent';
import './Chat.scss';

const Chat = ({ match }) => {
  const { channelId: currentChannelId } = match.params;
  const { idData } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messageSubscriptionRef = useRef(null);
  const currentChannelRef = useRef(currentChannelId);
  const liveCollectionRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { friendName } = location.state || { friendName: 'Chat' };

  const ensureClientConnected = async () => {
    if (!Client.isConnected()) {
      try {
        await Client.startSession({ userId: idData.sub, displayName: idData.name });
        console.log('Client connected successfully');
      } catch (error) {
        console.error('Client connection failed:', error);
      }
    }
  };

  const fetchMessages = async subChannelId => {
    if (messageSubscriptionRef.current && currentChannelRef.current === subChannelId) {
      return;
    }

    if (messageSubscriptionRef.current) {
      messageSubscriptionRef.current();
    }

    await ensureClientConnected();

    setIsLoading(true);

    currentChannelRef.current = subChannelId;

    const liveCollection = MessageRepository.getMessages(
      {
        subChannelId,
        limit: 50,
        reverse: true,
      },
      ({ data: fetchedMessages, loading, error, onNextPage, hasNextPage }) => {
        if (error) {
          console.error('Error fetching messages:', error);
          setIsLoading(false);
          return;
        }

        if (loading) {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }

        if (fetchedMessages) {
          setMessages(fetchedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
        }

        if (hasNextPage) {
          console.log('More messages available');
        } else {
          console.log('No more messages to load');
          setIsLoading(false);
        }

        if (liveCollectionRef.current && onNextPage) {
          liveCollectionRef.current.nextPage = onNextPage;
        }
      },
    );

    liveCollectionRef.current = liveCollection;
  };

  const loadMoreMessages = () => {
    if (liveCollectionRef.current && typeof liveCollectionRef.current.nextPage === 'function') {
      console.log('Loading more messages...');

      const nextPageResult = liveCollectionRef.current.nextPage();

      if (nextPageResult && typeof nextPageResult.then === 'function') {
        nextPageResult
          .then(() => {
            console.log('More messages loaded');
          })
          .catch(err => {
            console.error('Error loading more messages:', err);
          });
      } else {
        console.log('nextPage did not return a promise or there are no more messages to load.');
      }
    } else {
      console.log('No nextPage method available or no more messages to load');
    }
  };

  useEffect(() => {
    console.log('Fetching messages for currentChannelId:', currentChannelId);
    fetchMessages(currentChannelId);

    return () => {
      if (messageSubscriptionRef.current) {
        messageSubscriptionRef.current();
      }
    };
  }, [currentChannelId]);

  const sendMessage = async () => {
    if (!messageText.trim() || isSending) return;
    setIsSending(true);

    const textMessage = {
      subChannelId: currentChannelId,
      dataType: MessageContentType.TEXT,
      data: { text: messageText },
      tags: ['chat'],
      metadata: { data: 'textMessage' },
      userId: idData.sub,
    };

    try {
      await ensureClientConnected();

      const { data: message } = await MessageRepository.createMessage(textMessage);

      setMessages(prevMessages => {
        const combinedMessages = [...prevMessages, message];
        return combinedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      });

      setMessageText('');
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleChannelClick = (channelId, displayName) => {
    fetchMessages(channelId);
    history.replace(`/chat/${channelId}`, { friendName: displayName });
    setIsModalOpen(false);
  };

  const openRecentChatsModal = () => {
    setIsModalOpen(true);
  };

  const closeRecentChatsModal = () => {
    setIsModalOpen(false);
  };

  const closeChat = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="chat-toolbar">
          <IonButton className="close-button" slot="start" onClick={closeChat}>
            <IonIcon icon={arrowBack} />
          </IonButton>
          <IonTitle className="chat-title">{friendName}</IonTitle>
          <IonButton className="recent-chats-button" slot="end" onClick={openRecentChatsModal}>
            History
          </IonButton>
        </IonToolbar>
      </IonHeader>

      <IonContent className="chat-content">
        <ChatContent
          messages={messages}
          friendName={friendName}
          idData={idData}
          isLoading={isLoading}
          loadMoreMessages={loadMoreMessages}
        />
      </IonContent>

      <IonFooter className="chat-content-footer">
        <IonToolbar className="chat-input-wrapper">
          <div className="footer-chat-wrapper">
            <input
              className="chat-input"
              value={messageText}
              onChange={e => setMessageText(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter' && !isSending) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <IonButton className="chat-send-button" onClick={sendMessage} disabled={isSending} />
          </div>
        </IonToolbar>
      </IonFooter>

      <IonModal isOpen={isModalOpen} onDidDismiss={closeRecentChatsModal}>
        <IonHeader>
          <IonToolbar>
            <IonButton slot="start" onClick={closeRecentChatsModal}>
              Close
            </IonButton>
            <IonTitle>Recent Chats</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <RecentChats handleChannelClick={handleChannelClick} />
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Chat;
