import React, { useEffect, useState, useContext } from 'react';
import { IonContent, IonList, IonMenuToggle, IonItem, IonLabel, IonBadge } from '@ionic/react';
import { ChannelRepository } from '@amityco/ts-sdk';
import './RecentChats.scss';
import { AuthContext } from '../authentication/AuthContext';
import { allUsers } from '../../global/request/user';

const RecentChats = ({ handleChannelClick }) => {
  const [channels, setChannels] = useState([]);
  const [channelMembers, setChannelMembers] = useState({});
  const [allUsersData, setAllUsersData] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const authCtx = useContext(AuthContext);

  const cleanAndSetChannels = newChannels => {
    setChannels(prevChannels => {
      const combinedChannels = [...prevChannels, ...newChannels];
      const uniqueChannels = combinedChannels.reduce((acc, channel) => {
        if (!acc.some(existingChannel => existingChannel.channelId === channel.channelId)) {
          acc.push(channel);
        }
        return acc;
      }, []);

      return uniqueChannels;
    });
  };

  const fetchChannels = async (after = null) => {
    ChannelRepository.getChannels(
      {
        membership: 'member',
        sortBy: 'lastCreated',
        limit: 100,
        after,
      },
      ({ data, error, pagination }) => {
        if (error) {
          console.error('Error fetching channels:', error);
          return;
        }
        if (data) {
          cleanAndSetChannels(data);

          if (pagination?.hasNextPage) {
            fetchChannels(pagination.next);
          }
        }
      },
    );
  };

  const fetchChannelMembers = async channelId => {
    try {
      const result = await new Promise((resolve, reject) => {
        ChannelRepository.Membership.getMembers(
          { channelId, memberships: ['member'], sortBy: 'lastCreated' },
          ({ data: members, error, loading }) => {
            if (error) {
              console.error(`Error fetching members for channel ${channelId}:`, error);
              reject(new Error(`Error fetching members for channel ${channelId}`));
            } else if (loading) {
              console.log(`Loading members for channel ${channelId}...`);
            } else {
              const userIds = members?.map(member => member.userId) || [];
              console.log(`Fetched members for channel ${channelId}:`, userIds);
              resolve(userIds);
            }
          },
        );
      });
      return result;
    } catch (err) {
      console.error(`Unexpected error fetching members for channel ${channelId}:`, err);
      return [];
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await allUsers(authCtx.tokens.idToken);
      setAllUsersData(response);
      setIsLoadingUsers(false);
    } catch (error) {
      console.error('Error fetching all users:', error);
      setIsLoadingUsers(false);
    }
  };

  useEffect(() => {
    fetchChannels();
    fetchAllUsers();
  }, []);

  useEffect(() => {
    const fetchMembersForChannels = async () => {
      const memberPromises = channels.map(async channel => {
        const userIds = await fetchChannelMembers(channel.channelId);
        return { channelId: channel.channelId, userIds };
      });

      const members = await Promise.all(memberPromises);

      const memberMap = members.reduce((acc, { channelId, userIds }) => {
        acc[channelId] = userIds;
        return acc;
      }, {});

      setChannelMembers(memberMap);
    };

    if (channels.length > 0) {
      fetchMembersForChannels();
    }
  }, [channels]);

  const getUserNameById = userId => {
    if (isLoadingUsers) {
      return 'Loading...';
    }
    const foundUser = allUsersData.find(userRecord => userRecord.id === userId);
    return foundUser ? foundUser.name || 'Unknown User' : 'Unknown User';
  };

  const markAsRead = async channelId => {
    try {
      const channel = channels.find(ch => ch.channelId === channelId);
      if (channel?.markAsRead) {
        await channel.markAsRead();
        console.log(`Channel ${channelId} marked as read`);
        setChannels(prevChannels =>
          prevChannels.map(ch => (ch.channelId === channelId ? { ...ch, unreadCount: 0 } : ch)),
        );
      }
    } catch (error) {
      console.error(`Error marking channel ${channelId} as read:`, error);
    }
  };

  const handleChannelClickInternal = (channelId, userDisplayNames) => {
    markAsRead(channelId);
    handleChannelClick(channelId, userDisplayNames);
  };

  return (
    <IonContent>
      <IonList>
        {channels.map(channel => {
          const userIds = channelMembers[channel.channelId] || [];
          const filteredUserIds = userIds.filter(id => id !== authCtx?.idData?.sub);

          const userDisplayNames = filteredUserIds.map(userId => getUserNameById(userId)).join(', ');

          const unreadCount = channel.unreadCount || 0;

          return (
            <IonMenuToggle key={channel.channelId} autoHide={false}>
              <IonItem button onClick={() => handleChannelClickInternal(channel.channelId, userDisplayNames)}>
                <IonLabel>{userDisplayNames || 'Loading...'}</IonLabel>
                {unreadCount > 0 && (
                  <IonBadge color="danger" style={{ marginLeft: '8px' }}>
                    {unreadCount}
                  </IonBadge>
                )}
              </IonItem>
            </IonMenuToggle>
          );
        })}
      </IonList>
    </IonContent>
  );
};

export default RecentChats;
