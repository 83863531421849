import React, { useRef, useEffect, useState } from 'react';
import { IonContent, IonList, IonItem, IonLabel } from '@ionic/react';
import './ChatContent.scss';

const formatTime = date => {
  const options = { hour: '2-digit', minute: '2-digit' };
  return new Date(date).toLocaleTimeString([], options);
};

const formatDate = date => {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  return new Date(date).toLocaleDateString([], options);
};

const isSameDay = (date1, date2) => {
  return new Date(date1).toLocaleDateString() === new Date(date2).toLocaleDateString();
};

const ChatContent = ({ messages, idData, isLoading, loadMoreMessages, channelId }) => {
  const chatContainerRef = useRef(null);
  const [hasLoadedInitialMessages, setHasLoadedInitialMessages] = useState(false);
  const [scrollPositionBeforeLoad, setScrollPositionBeforeLoad] = useState(0);

  useEffect(() => {
    if (chatContainerRef.current && messages.length > 0 && !hasLoadedInitialMessages) {
      chatContainerRef.current.scrollToBottom(0);
      setHasLoadedInitialMessages(true);
    }
  }, [messages, channelId, hasLoadedInitialMessages]);

  const handleScroll = ({ detail: { scrollTop } }) => {
    if (scrollTop === 0) {
      console.log('You reached top, loading more messages');

      chatContainerRef.current.getScrollElement().then(scrollElem => {
        setScrollPositionBeforeLoad(scrollElem.scrollHeight);
        loadMoreMessages();
      });
    }
  };

  useEffect(() => {
    if (scrollPositionBeforeLoad && messages.length > 0) {
      chatContainerRef.current.getScrollElement().then(scrollElem => {
        const newScrollHeight = scrollElem.scrollHeight;
        chatContainerRef.current.scrollToPoint(0, newScrollHeight - scrollPositionBeforeLoad, 0);
      });
    }
  }, [messages, scrollPositionBeforeLoad]);

  const renderMessagesWithDate = () => {
    const renderedMessages = [];
    let lastMessageDate = null;

    messages.forEach(message => {
      const messageDate = new Date(message.createdAt);
      const formattedMessageDate = formatDate(messageDate);

      if (!lastMessageDate || !isSameDay(lastMessageDate, messageDate)) {
        renderedMessages.push(
          <div key={`date-${message.createdAt}`} className="date-marker">
            <div>{formattedMessageDate}</div>
          </div>,
        );
        lastMessageDate = messageDate;
      }

      const isSentByCurrentUser = message.creatorId === idData.sub;
      renderedMessages.push(
        <IonItem
          key={message.messageId || message.createdAt}
          className={`message-item ${isSentByCurrentUser ? 'sent' : 'received'}`}
        >
          <p>{message.data.text}</p>
          <IonLabel className="message-time">{formatTime(message.createdAt)}</IonLabel>
        </IonItem>,
      );
    });

    return renderedMessages;
  };

  return (
    <IonContent ref={chatContainerRef} scrollEvents onIonScroll={handleScroll}>
      <IonList className="chat-list">{renderMessagesWithDate()}</IonList>
      {isLoading && <p className="loading-indicator">Loading...</p>}
    </IonContent>
  );
};

export default ChatContent;
